import { FC, useState } from "react"
import {
    SubmitHandler,
    UseFieldArrayAppend,
    UseFieldArrayRemove,
} from "react-hook-form"
import { IGuard, ILicense } from "types/index"
import { FormValues, TABS } from "pages/Guard/AddGuard"
import classNames from "classnames"
import { inputStyles } from "components/Form/InputField"
import { addGuardLicense, updateGuardLicense } from "services/guard.services"
import { BsFillTrash3Fill } from "react-icons/bs"
import { useNotifications } from "hooks/useNotification"
import { Button } from "components/Form/Button"
import { useRouter } from "hooks/useRouter"

interface IProps extends IGuard<FormValues> {
    fields: any
    append: any
    remove: any
}

export const GuardLicense: FC<IProps> = ({
    handleSubmit,
    setComponent,
    errors,
    register,
    guardId,
    append,
    remove,
    fields,
}) => {
    const router = useRouter()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [click, setClick] = useState<"save" | "saveNext">("save")
    const { successMessage } = useNotifications()

    const onSubmit: SubmitHandler<FormValues> = async data => {
        setIsSubmitting(true)

        try {
            if (router?.location?.state) {
                const guardId = (router?.location?.state as { _id: string })
                    ?._id
                await updateGuardLicense(
                    data.license.map((license: any) => ({
                        licenseNumber: license.licenseNumber,
                        licenseTitle: license.licenseTitle,
                        licenseExpiry: license.licenseExpiry.toISOString(),
                    })),
                    guardId,
                )
                    .then(resp => {
                        successMessage("Guard license updated successfully")
                        setComponent(TABS.CITIZENSHIP)
                        setIsSubmitting(false)
                    })
                    .catch(error => console.log(error))
            } else {
                await addGuardLicense(
                    data.license.map((license: any) => ({
                        licenseNumber: license.licenseNumber,
                        licenseTitle: license.licenseTitle,
                        licenseExpiry: license.licenseExpiry.toISOString(),
                    })),
                    guardId as string,
                )
                successMessage("Guard license added successfully")
                setComponent(TABS.CITIZENSHIP)
                setIsSubmitting(false)
            }
        } catch (err) {
            setIsSubmitting(false)
        }
    }

    const handleAddNewLicense = () => {
        if (fields.length === 3) return
        append({
            licenseNumber: "",
            licenseTitle: "",
            licenseExpiry: new Date(),
        })
    }

    return (
        <div className="bg-white rounded-md shadow-lg overflow-hidden">
            <div className="bg-gray-50 border-b px-7 py-4">
                <p className="font-semibold text-gray-700 tracking-wide">
                    Guard Licenses
                </p>
            </div>
            <div className="px-7 pb-7 pt-2">
                <form onSubmit={handleSubmit(onSubmit)}>
                    {fields.map((field: ILicense, index: any) => (
                        <div key={index} className="mt-4">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div className="">
                                    <label
                                        htmlFor="label"
                                        className="block text-sm font-semibold tracking-wide text-gray-700 "
                                    >
                                        License Title
                                        <span className="text-red-500">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        {...register(
                                            `license.${index}.licenseTitle`,
                                        )}
                                        placeholder="License Title"
                                        className={classNames(
                                            inputStyles.input,
                                            {
                                                [inputStyles.error]:
                                                    !!errors.license?.[index]
                                                        ?.licenseTitle?.message,
                                                [inputStyles.focused]:
                                                    !errors.license?.[index]
                                                        ?.licenseTitle?.message,
                                            },
                                        )}
                                    />
                                    <p className={inputStyles.errorMessage}>
                                        {
                                            errors.license?.[index]
                                                ?.licenseTitle?.message
                                        }
                                    </p>
                                </div>
                                <div className="">
                                    <label
                                        htmlFor="label"
                                        className="block text-sm font-semibold tracking-wide text-gray-700"
                                    >
                                        License Number
                                        <span className="text-red-500">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        {...register(
                                            `license.${index}.licenseNumber`,
                                        )}
                                        placeholder="License Number"
                                        className={classNames(
                                            inputStyles.input,
                                            {
                                                [inputStyles.error]:
                                                    !!errors.license?.[index]
                                                        ?.licenseNumber
                                                        ?.message,
                                                [inputStyles.focused]:
                                                    !errors.license?.[index]
                                                        ?.licenseNumber
                                                        ?.message,
                                            },
                                        )}
                                    />
                                    <p className={inputStyles.errorMessage}>
                                        {
                                            errors.license?.[index]
                                                ?.licenseNumber?.message
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
                                <div className="">
                                    <label
                                        htmlFor="label"
                                        className="block text-sm font-semibold tracking-wide text-gray-700 "
                                    >
                                        License Expiry
                                        <span className="text-red-500">*</span>
                                    </label>
                                    <input
                                        type="date"
                                        {...register(
                                            `license.${index}.licenseExpiry`,
                                        )}
                                        className={classNames(
                                            inputStyles.input,
                                            {
                                                [inputStyles.error]:
                                                    !!errors.license?.[index]
                                                        ?.licenseExpiry
                                                        ?.message,
                                                [inputStyles.focused]:
                                                    !errors.license?.[index]
                                                        ?.licenseExpiry
                                                        ?.message,
                                            },
                                        )}
                                    />
                                    <p className={inputStyles.errorMessage}>
                                        {
                                            errors.license?.[index]
                                                ?.licenseExpiry?.message
                                        }
                                    </p>
                                </div>
                                {index !== 0 && (
                                    <button
                                        type="button"
                                        onClick={() => remove(index)}
                                        className="w-10 mt-5"
                                    >
                                        <BsFillTrash3Fill className="text-red-500 h-5 w-5 " />
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                    <p className="text-sm italic text-red-500 mt-2">
                        Note:* Add upto three
                    </p>
                    <div className="flex items-center gap-2 mt-4">
                        <Button
                            type="submit"
                            disabled={isSubmitting && click === "save"}
                            onClick={() => setClick("save")}
                            value="Save"
                        />
                        <Button
                            type="submit"
                            disabled={isSubmitting && click === "saveNext"}
                            onClick={() => setClick("saveNext")}
                            bg="bg-logo-black"
                            value={"Save & Next"}
                        />
                        <Button
                            type="button"
                            disabled={fields.length === 3}
                            onClick={handleAddNewLicense}
                            value="Add New License"
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
