import React, { FC, SetStateAction, useState } from "react"
import { useEffect } from "react"
import { TableBody } from "./TableBody"
import { TableHeader } from "./TableHeader"
import { Paginate } from "../Paginate"
import { Pagination } from "./Paginations"

export interface IColumn {
    title: string
    render?: any
    key?: string
    dbColName?: string
    select?: boolean
}

export interface ITable {
    data: Array<any>
    setData: React.Dispatch<SetStateAction<any>>
    className?: string
    rowSelection?: boolean
    columns: any
    loadingData?: boolean
    pagination?: any
    // setSelectedIds?: any
    // selectedIds?: any
}

export const Table: FC<ITable> = ({
    data,
    columns,
    loadingData,
    setData,
    className,
    rowSelection,
    pagination,
    // selectedIds,
    // setSelectedIds,
}) => {
    // const handleRowSelection = (id: number) => {
    //     setData((prevData: any) => {
    //         return prevData.map((item: any) => {
    //             if (item.id === id) {
    //                 return { ...item, select: !item.select }
    //             }
    //             return item
    //         })
    //     })
    // }
    const [currentPage, setCurrentPage] = useState(1)
    useEffect(() => {
        setData((prev: any) => {
            const newData = [...prev]
            if (rowSelection === false) return newData
            newData.forEach(current => {
                current.select = false
            })
            return newData
        })
    }, [])

    return (
        <div>
            {/* <div>
                <Pagination
                    currentPage={currentPage}
                    itemsPerPage={pagination?.itemPerPage}
                    setCurrentPage={setCurrentPage}
                    totalItems={pagination?.totalResult}
                    showingData={pagination?.itemPerPage}
                />
            </div> */}
            <div className="border shadow-lg overflow-y-auto rounded-md">
                <table
                    className={`${className} bg-white min-w-full divide-y divide-gray-200 `}
                >
                    <TableHeader
                        rowSelection={rowSelection}
                        setData={setData}
                        data={data}
                        columns={columns}
                        // selectedIds={selectedIds}
                        // setSelectedIds={setSelectedIds}
                    />
                    <TableBody
                        rowSelection={rowSelection}
                        setData={setData}
                        data={data}
                        columns={columns}
                        loadingData={loadingData}
                        // selectedIds={selectedIds} // Pass selectedIds as a prop to the Table component
                        // setSelectedIds={setSelectedIds}
                    />
                </table>
            </div>
        </div>
    )
}
