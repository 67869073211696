import { AppThunk } from "redux/configureStore"
import {
    loginError,
    loginRequest,
    loginSuccess,
    logout,
    logoutSuccess,
} from "redux/reducers/userReducer"
import { loginUser, logoutUser, removeItem, setItem } from "services"
import { ILoginForm } from "types"
import { ROUTES } from "utils/constants"
import { redirectTo } from "utils/links"

export const login =
    ({ email, password }: ILoginForm): AppThunk =>
    async dispatch => {
        try {
            dispatch(loginRequest())
            const response = await loginUser({ email, password })
            dispatch(loginSuccess(response.data))
            setItem("x-auth-token", response.headers["x-auth-token"])
        } catch (error: any) {
            dispatch(loginError(error.response))
        }
    }

export const logOut = (): AppThunk => async dispatch => {
    try {
        dispatch(logout())
        await logoutUser()
        redirectTo(ROUTES.LOGIN)
        dispatch(logoutSuccess())
        removeItem("x-auth-token")
    } catch (error) {
        dispatch(loginError(error))
    }
}
