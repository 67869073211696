import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    loader: false,
    isLoggedIn: false,
    user: null,
    authErrors: null,
}

const userReducer = createSlice({
    name: "user",
    initialState,
    reducers: {
        loginRequest: state => {
            state.loader = true
        },
        loginSuccess: (state, action) => {
            state.user = action.payload
            state.isLoggedIn = true
            state.loader = false
            state.authErrors = null
        },
        loginFailure: state => {
            state.loader = false
        },
        loginError: (state, action) => {
            state.loader = false
            state.authErrors = action.payload
        },
        logout: state => {
            state.loader = true
        },
        logoutSuccess: state => {
            state.user = null
            state.loader = false
            state.isLoggedIn = false
        },
        logoutFailure: state => {
            state.loader = false
        },
    },
})

export const {
    loginRequest,
    loginSuccess,
    logoutFailure,
    loginFailure,
    loginError,
    logout,
    logoutSuccess,
} = userReducer.actions

export default userReducer.reducer
