import {
    IAvailibility,
    ICitizenship,
    IGuardDetails,
    IGuardRating,
    ILicense,
} from "types/index"
import axiosInstance from "utils/axiosInstance"

const fetchGuards = () =>
    axiosInstance.get("guards?unscheduled=true").then(resp => resp.data)

const fetchGuardsWithHours = (params: any) =>
    axiosInstance.get("guards/hours", { params }).then(resp => resp.data)
const fetchGuardDetails = (id: any) =>
    axiosInstance.get(`time/hours/${id}`).then(resp => resp.data)

const addGuardInfo = (data: IGuardDetails) => axiosInstance.post("guards", data)

const updateGuardInfo = (data: IGuardDetails, guardId: string) =>
    axiosInstance.patch(`guards/update/${guardId}`, data)

const addGuardLicense = (data: any, id: any) => {
    return axiosInstance.post(`guards/license/${id}`, {
        licenses: data,
    })
}
const updateGuardLicense = (
    data: { licenseExpiry: string; licenseTitle: any; licenseNumber: any }[],
    guardId: string | undefined,
) => axiosInstance.patch(`guards/license/update/${guardId}`, { licenses: data })

const addGuardCitizenship = (data: any, guardId: any) => {
    return axiosInstance.post("guards/citizenship", { ...data, guardId })
}

const updateGuardCitizenship = (data: any) =>
    axiosInstance.patch("guards/citizenship/update", data)

const addGuardRating = (data: IGuardRating) =>
    axiosInstance.post("performance-evaluation", data)
const updateGuardRating = (data: IGuardRating) => {
    const { id, ...dataWithoutId } = data
    return axiosInstance.patch(
        `performance-evaluation/update/${id}`,
        dataWithoutId,
    )
}
const getGuardRating = () =>
    axiosInstance.get("performance-evaluation").then(resp => resp?.data)

// availablity
const addGuardAvailablity = (data: any, guardId: any) => {
    return axiosInstance.post(`guards/availability`, { ...data, guardId })
}
const updateGuardAvailability = (data: any, guardId: any) =>
    axiosInstance.patch("guards/availability/update", { ...data, guardId })
//delete guard
const deleteGuard = (id: any) => {
    return axiosInstance.delete(`guards/delete/${id}`)
}

export {
    fetchGuards,
    addGuardInfo,
    addGuardLicense,
    addGuardCitizenship,
    addGuardRating,
    addGuardAvailablity,
    deleteGuard,
    fetchGuardsWithHours,
    fetchGuardDetails,
    updateGuardInfo,
    updateGuardLicense,
    updateGuardCitizenship,
    updateGuardAvailability,
    getGuardRating,
    updateGuardRating,
}
