import React from "react"
import { Route, Redirect, RouteProps } from "react-router-dom"

interface IProps extends RouteProps {
    component: React.FC<RouteProps>
    permittedRoles?: string[]
    userRoles?: string[]
    authorized: boolean
}

export const ProtectedRoute: React.FC<IProps> = ({
    component: Component,
    permittedRoles,
    authorized,
    userRoles,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            exact
            render={p => {
                if (!authorized) {
                    return (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: p.location },
                            }}
                        />
                    )
                }

                if (
                    Boolean(permittedRoles?.length) &&
                    !checkRoles(userRoles, permittedRoles)
                ) {
                    return <Redirect to="/" />
                } else {
                    return <Component {...p} />
                }
            }}
        />
    )
}

function checkRoles(userRoles?: string[], permittedRoles?: string[]) {
    return userRoles?.some(ur => permittedRoles?.includes(ur))
}
