import { useEffect, useState } from "react"
import {
    fetchGuardDetails,
    fetchGuards,
    fetchGuardsWithHours,
} from "services/guard.services"
import { IGuardDetails } from "types/index"

export const useFetchGuardDetails = (id: any) => {
    const [guards, setGuards] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)
    // const [pagination, setPagination] = useState<any>()
    
    useEffect(() => {
        setLoading(true)
        fetchGuardDetails(id)
            // fetchGuards()
            .then(resp => {
                setGuards(resp)
                setLoading(false)
                // setPagination(resp?.pagination)
            })
            .catch(err => {
                setLoading(false)
            })
    }, [])

    return {
        guards,
        loading,
        setGuards,
        // pagination,
    }
}
