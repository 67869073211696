import { FC, useState } from "react"

interface IProps {
    onClick: (index: number) => void
    value: number
}

export const ReactStars: FC<IProps> = ({ onClick, value }) => {
    const [rating, setRating] = useState(value)
    const [hover, setHover] = useState(0)
    return (
        <div className="star-rating">
            {[...Array(5)].map((star, index) => {
                index += 1
                return (
                    <button
                        key={index}
                        type="button"
                        className={
                            index <= (hover || rating)
                                ? "text-logo-yellow"
                                : "text-gray-200"
                        }
                        onClick={() => {
                            setRating(index)
                            onClick(index)
                        }}
                        onMouseEnter={() => setHover(index)}
                        onMouseLeave={() => setHover(rating)}>
                        <span className="star flex gap-4 text-3xl">
                            &#9733;
                        </span>
                    </button>
                )
            })}
        </div>
    )
}
