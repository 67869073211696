import { Button } from "components/Form/Button"
import { InputField } from "components/Form/InputField"
import { useNotifications } from "hooks/useNotification"
import { FormValues, TABS } from "pages/Guard/AddGuard"
import { FC, useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import {
    addGuardCitizenship,
    updateGuardCitizenship,
} from "services/guard.services"
import { ICitizenship, IGuard } from "types/index"
import { useRouter } from "hooks/useRouter"

// interface CitizenshipFormProps {
//     guardId: string;
//     setComponent: (tab: TABS) => void;
// }
export const GuardCitizenship: FC<IGuard<ICitizenship>> = ({
    // handleSubmit,
    // register,
    guardId,
    errors,
    setComponent,
}) => {
    const router = useRouter()
    const { register, handleSubmit, setValue } = useForm()
    const [click, setClick] = useState<"save" | "saveNext">("save")
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { successMessage, errorMessage } = useNotifications()
    const [selectedOption, setSelectedOption] = useState("passport")

    // const handleAddCitizenship = handleSubmit(async (data, event) => {
    //     event?.preventDefault()
    //     setIsSubmitting(true)
    //     try {
    //         await addGuardCitizenship(data, guardId)
    //         if (click === "saveNext") setComponent(TABS.AVAILABILITY)
    //         successMessage("Guard citizenship added successfully")
    //         setIsSubmitting(false)
    //     } catch (err) {
    //         setIsSubmitting(false)
    //     }
    // })

    useEffect(() => {
        if (
            router?.location?.state &&
            (router.location.state as { citizenship: ICitizenship }).citizenship
        ) {
            const existingCitizenship = (
                router.location.state as { citizenship: ICitizenship }
            ).citizenship
            setValue("passportNumber", existingCitizenship.passportNumber || "")
            setValue("workPermit", existingCitizenship.workPermit || "")
            setValue(
                "greenCardHolder",
                existingCitizenship.greenCardHolder || "",
            )
            setValue(
                "greenCardExpiryDate",
                existingCitizenship?.greenCardExpiryDate
                    ? new Date(existingCitizenship?.greenCardExpiryDate)
                          .toISOString()
                          .split("T")[0]
                    : new Date(),
            )
            setValue(
                "passportNumberExpiryDate",
                existingCitizenship?.passportNumberExpiryDate
                    ? new Date(existingCitizenship?.passportNumberExpiryDate)
                          .toISOString()
                          .split("T")[0]
                    : new Date(),
            )
            setValue(
                "workPermitExpiryDate",
                existingCitizenship?.workPermitExpiryDate
                    ? new Date(existingCitizenship?.workPermitExpiryDate)
                          .toISOString()
                          .split("T")[0]
                    : new Date(),
            )
        }
    }, [router?.location?.state, setValue])

    // Test on submit form
    const onSubmit = async (data: any) => {
        setIsSubmitting(true)
        try {
            if (router.location.state) {
                const citizenData = {
                    guardId: (router.location.state as { _id: string })?._id,
                    passportNumber: data?.passportNumber,
                    greenCardHolder: data?.greenCardHolder,
                    greenCardExpiryDate: data?.greenCardExpiryDate,
                    passportNumberExpiryDate: data?.passportNumberExpiryDate,
                    workPermit: data?.workPermit,
                    workPermitExpiryDate: data?.workPermitExpiryDate,
                }
                await updateGuardCitizenship(citizenData)
                    .then(() => {
                        if (click === "saveNext")
                            setComponent(TABS.AVAILABILITY)
                        successMessage("Guard citizenship added successfully")
                        setIsSubmitting(false)
                    })
                    .catch(error => console.log("Error", error))
            } else {
                await addGuardCitizenship(data, guardId)
                    .then(() => {
                        if (click === "saveNext")
                            setComponent(TABS.AVAILABILITY)
                        successMessage("Guard citizenship added successfully")
                        setIsSubmitting(false)
                    })
                    .catch(error => errorMessage(error?.response?.data))
            }
        } catch (err) {
            setIsSubmitting(false)
        }
    }
    const handleOptionChange = (option: string) => {
        setSelectedOption(option)
    }

    return (
        <div className="bg-white rounded-md shadow-lg overflow-hidden">
            <div className="bg-gray-50 flex items-center gap-10 border-b px-7 py-4">
                <p className="font-semibold text-gray-700 tracking-wide">
                    Guard Citizenship
                </p>
                <div className="">
                    <label className="mr-4">
                        <input
                            type="radio"
                            value="passport"
                            checked={selectedOption === "passport"}
                            onChange={() => handleOptionChange("passport")}
                        />
                        Passport
                    </label>
                    <label className="mr-4">
                        <input
                            type="radio"
                            value="greenCard"
                            checked={selectedOption === "greenCard"}
                            onChange={() => handleOptionChange("greenCard")}
                        />
                        Green Card
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="workPermit"
                            checked={selectedOption === "workPermit"}
                            onChange={() => handleOptionChange("workPermit")}
                        />
                        Work Permit
                    </label>
                </div>
            </div>
            <div className="px-7 pb-7 pt-2">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-2 gap-4">
                        {selectedOption === "passport" && (
                            <>
                                <InputField
                                    type="text"
                                    placeholder="Passport Number"
                                    errorMessage={
                                        errors.passportNumber?.message
                                    }
                                    label="Passport Number"
                                    name="passportNumber"
                                    register={register}
                                />
                                <InputField
                                    type="date"
                                    placeholder="Passport expiry"
                                    errorMessage={
                                        errors.passportNumberExpiryDate?.message
                                    }
                                    label="Passport Expiry"
                                    name="passportNumberExpiryDate"
                                    register={register}
                                />
                            </>
                        )}
                        {selectedOption === "greenCard" && (
                            <>
                                <InputField
                                    type="text"
                                    placeholder="Green card holder number"
                                    errorMessage={
                                        errors.greenCardHolder?.message
                                    }
                                    label="Green Card Holder Number"
                                    name="greenCardHolder"
                                    register={register}
                                />
                                <InputField
                                    type="date"
                                    placeholder="Green Card Expiry"
                                    errorMessage={
                                        errors.greenCardExpiryDate?.message
                                    }
                                    label="Green Card Expiry"
                                    name="greenCardExpiryDate"
                                    register={register}
                                />
                            </>
                        )}
                        {selectedOption === "workPermit" && (
                            <>
                                <InputField
                                    type="text"
                                    placeholder="Work Permit"
                                    errorMessage={errors.workPermit?.message}
                                    label="Work Permit"
                                    name="workPermit"
                                    register={register}
                                />
                                <InputField
                                    type="date"
                                    placeholder="Work Permit Expiry"
                                    errorMessage={
                                        errors.workPermitExpiryDate?.message
                                    }
                                    label="Work Permit Expiry"
                                    name="workPermitExpiryDate"
                                    register={register}
                                />
                            </>
                        )}
                    </div>

                    <div className="mt-4">
                        <Button
                            type="submit"
                            // disabled={"saveNext"}
                            onClick={() => setClick("saveNext")}
                            bg="bg-logo-black"
                            value={"Save & Next"}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
