import { FC, Fragment, useEffect, useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"

import { GuardInfo, GuardLicense, GuardCitizenship } from "components/GuardForm"
import classNames from "classnames"
import {
    IAvailibility,
    ICitizenship,
    IGuardDetails,
    ILicense,
} from "types/index"
import { yupResolver } from "@hookform/resolvers/yup"
import {
    CitizenshipValidation,
    GuardValidation,
    LicenseValidation,
} from "utils/validation"
import { GuardAvailability } from "components/GuardForm/GuardAvailability"
import { useRouter } from "hooks/useRouter"

export const TABS = {
    INFO: "info",
    LICENSE: "license",
    CITIZENSHIP: "citizenship",
    AVAILABILITY: "availability",
}

export type FormValues = {
    license: ILicense[]
}

export const AddGuard: FC = () => {
    const router = useRouter()
    const [component, setComponent] = useState<string>(TABS.INFO)
    const [guardId, setGuardId] = useState<string>("")
    const {
        handleSubmit: handleAddInfo,
        register: infoRegister,
        setValue,
        formState: { errors: infoErrors },
    } = useForm<IGuardDetails>({
        defaultValues: {
            userId: "",
            address: {
                addressLine1: "",
                addressLine2: "",
                state: "",
                // street: "",
                city: "",
                zipCode: "",
            },
            gender: "",
            contactNumber: "",
            emergencyContactNumber: "",
            type: "",
        },
        resolver: yupResolver(GuardValidation),
    })

    const {
        control,
        handleSubmit: handleAddLicense,
        register: licenseRegister,
        setValue: setLicenseValue,
        formState: { errors: licenseErrors },
    } = useForm<FormValues>({
        defaultValues: {
            license: [
                {
                    guardId,
                    licenseNumber: "",
                    licenseTitle: "",
                    licenseExpiry: new Date(),
                },
            ],
        },
        resolver: yupResolver(LicenseValidation),
    })
    const { fields, append, remove } = useFieldArray<FormValues>({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "license", // unique name for your Field Array
    })

    const {
        handleSubmit: handleAddCitizenship,
        register: citizenshipRegister,
        formState: { errors: citizenshipErrors },
    } = useForm<ICitizenship>({
        defaultValues: {
            guardId,
            passportNumber: "",
            greenCardHolder: "",
            greenCardExpiryDate: new Date(),
            workPermit: "",
            workPermitExpiryDate: new Date(),
            passportNumberExpiryDate: new Date(),
        },
        resolver: yupResolver(CitizenshipValidation),
    })
    const {
        handleSubmit: handleAddAvailability,
        register: availibilityRegister,
        watch,
        formState: { errors: availibilityErrors },
    } = useForm<IAvailibility>({
        defaultValues: {
            guardId,
            type: "",
            timing: {
                fromDate: new Date(),
                toDate: new Date(),
            },
        },
        resolver: yupResolver(CitizenshipValidation),
    })

    useEffect(() => {
        if (router?.location?.state) {
            setValue(
                "userId",
                String(
                    (router?.location?.state as { userId: { _id: string } })
                        ?.userId?._id || "",
                ),
            )
            setValue(
                "address.state",
                (router?.location?.state as { address: { state: string } })
                    ?.address?.state || "",
            )
            setValue(
                "address.addressLine1",
                (
                    router?.location?.state as {
                        address: { addressLine1: string }
                    }
                )?.address?.addressLine1 || "",
            )
            setValue(
                "address.addressLine2",
                (
                    router?.location?.state as {
                        address: { addressLine2: string }
                    }
                )?.address?.addressLine2 || "",
            )
            setValue(
                "gender",
                (router?.location?.state as { gender: string })?.gender || "",
            )
            setValue(
                "contactNumber",
                (router?.location?.state as { contactNumber: string })
                    ?.contactNumber || "",
            )
            setValue(
                "address.city",
                (router?.location?.state as { address: { city: string } })
                    ?.address?.city || "",
            )
            setValue(
                "address.zipCode",
                (router?.location?.state as { address: { zipCode: string } })
                    ?.address?.zipCode || "",
            )
            // setValue(
            //     "address.street",
            //     (router?.location?.state as { address: { street: string } })
            //         ?.address?.street || "",
            // )
            setValue(
                "emergencyContactNumber",
                (router?.location?.state as { emergencyContactNumber: string })
                    ?.emergencyContactNumber || "",
            )
            setValue(
                "type",
                (router?.location?.state as { type: string })?.type || "",
            )
        }
        if (
            router?.location?.state &&
            (router.location.state as { licenses?: ILicense[] }).licenses
        ) {
            (
                router.location.state as { licenses: ILicense[] }
            ).licenses.forEach((existingLicense: ILicense, index: number) => {
                setLicenseValue(
                    `license[${index}].guardId` as any,
                    existingLicense?.guardId || "",
                )
                setLicenseValue(
                    `license[${index}].licenseNumber` as any,
                    existingLicense?.licenseNumber || "",
                )
                setLicenseValue(
                    `license[${index}].licenseTitle` as any,
                    existingLicense?.licenseTitle || "",
                )
                setLicenseValue(
                    `license[${index}].licenseExpiry` as any,
                    existingLicense?.licenseExpiry
                        ? new Date(existingLicense?.licenseExpiry)
                              .toISOString()
                              .split("T")[0]
                        : "",
                )
            })
        }
    }, [router?.location?.state, setValue])

    const info = component === TABS.INFO
    const license = component === TABS.LICENSE
    const citizenship = component === TABS.CITIZENSHIP
    const availability = component === TABS.AVAILABILITY

    const renderComponent = () => {
        switch (component) {
            case TABS.INFO:
                return (
                    <GuardInfo
                        setGuardId={setGuardId}
                        handleSubmit={handleAddInfo}
                        register={infoRegister}
                        errors={infoErrors}
                        setComponent={setComponent}
                    />
                )
            case TABS.LICENSE:
                return (
                    <GuardLicense
                        guardId={guardId}
                        fields={fields}
                        append={append}
                        remove={remove}
                        handleSubmit={handleAddLicense}
                        register={licenseRegister}
                        errors={licenseErrors}
                        setComponent={setComponent}
                    />
                )
            case TABS.CITIZENSHIP:
                return (
                    <GuardCitizenship
                        guardId={guardId}
                        handleSubmit={handleAddCitizenship}
                        register={citizenshipRegister}
                        errors={citizenshipErrors}
                        setComponent={setComponent}
                    />
                )
            case TABS.AVAILABILITY:
                return (
                    <GuardAvailability
                        guardId={guardId}
                        handleSubmit={handleAddAvailability}
                        register={availibilityRegister}
                        watch={watch}
                        errors={availibilityErrors}
                        setComponent={setComponent}
                    />
                )
        }
    }

    return (
        <Fragment>
            <div className="overflow-hidden rounded-md border border-gray-100 bg-logo-yellow/90 p-1">
                <ul className="flex items-center gap-2 text-sm font-medium">
                    <li className="flex-1">
                        <span
                            onClick={() => setComponent(TABS.INFO)}
                            className={classNames(styles.inactive, {
                                [styles.active]: info,
                                "text-white": !info,
                            })}
                        >
                            Information
                        </span>
                    </li>
                    <li className="flex-1">
                        <span
                            onClick={() => setComponent(TABS.LICENSE)}
                            className={classNames(styles.inactive, {
                                [styles.active]: license,
                                "text-white": !license,
                            })}
                        >
                            Licenses
                        </span>
                    </li>
                    <li className="flex-1">
                        <span
                            onClick={() => setComponent(TABS.CITIZENSHIP)}
                            className={classNames(styles.inactive, {
                                [styles.active]: citizenship,
                                "text-white": !citizenship,
                            })}
                        >
                            Citizenship
                        </span>
                    </li>
                    <li className="flex-1">
                        <span
                            onClick={() => setComponent(TABS.AVAILABILITY)}
                            className={classNames(styles.inactive, {
                                [styles.active]: availability,
                                "text-white": !availability,
                            })}
                        >
                            Availability
                        </span>
                    </li>
                </ul>
            </div>
            <div className="py-3">{renderComponent()}</div>
        </Fragment>
    )
}

const styles = {
    active: "bg-white shadow text-logo-black",
    inactive:
        "flex items-center transition ease-in duration-500 hover:bg-white hover:text-gray-700 hover:shadow justify-center gap-2 rounded p-3  tracking-wide cursor-pointer",
}
