import React, { FC } from "react"
import classNames from "classnames"
import { Link } from "react-router-dom"

type Props = {
    active: boolean
    icon?: React.ReactNode
    label: string
    to: string
    className?: string
}

export const NavLink: FC<Props> = ({ active, to, icon, label, className }) => {
    return (
        <Link
            to={to}
            className={classNames(
                "flex py-1 px-6 hover:border-r-2 items-center w-full " +
                    className,
                {
                    "bg-gray-100 text-yellow-600 border-r-4 border-r-logo-yellow":
                        active,
                    "text-gray-800 hover:text-yellow-600 hover:bg-gray-100 ease-in transition duration-500":
                        !active,
                },
            )}>
            {icon}
            <span
                className={classNames(
                    "hover:text-yellow-600 p-3 tracking-wider rounded-md",
                    {
                        "text-yellow-600": active,
                    },
                )}>
                {label}
            </span>
        </Link>
    )
}
