import { FC, useCallback, useEffect } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { InputField } from "components/Form/InputField"
import { LoginValidation } from "utils/validation"
import { ILoginForm } from "../../types"
import { useSelector } from "react-redux"
import { login } from "redux/actions/user"
import { loadingSelector } from "redux/selectors/user"
import { useThunkDispatch } from "hooks/useThunkDispatch"
import { Button } from "components/Form/Button"
import { loginError } from "redux/reducers/userReducer"

export const LoginPage: FC = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ILoginForm>({
        resolver: yupResolver(LoginValidation),
    })

    const loader = useSelector(loadingSelector)
    const authError = useSelector(
        (state: { user: any }) => state.user.authErrors,
    )

    useEffect(() => {
        dispatch(loginError(null))
    }, [])

    const dispatch = useThunkDispatch()

    const onSubmit: SubmitHandler<ILoginForm> = useCallback(data => {
        dispatch(login(data))
    }, [])

    return (
        <div className="flex flex-col justify-center items-center min-h-screen bg-gray-50 p-5 font-poppins">
            <div className="w-full max-w-md">
                <p className="text-center font-bold font-poppins text-logo-yellow text-2xl mt-5">
                    Sign in to your account
                </p>
                <div className="bg-white shadow  md:p-10 mt-6 rounded-md">
                    <p className="text-center text-red-500">
                        {authError?.data}
                    </p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <InputField
                            placeholder="Enter your email address"
                            label="Email"
                            register={register}
                            layout="mt-2"
                            errorMessage={errors.email?.message}
                            type="email"
                            name="email"
                        />
                        <InputField
                            type="password"
                            placeholder="Enter your password"
                            label="Password"
                            layout="mt-5"
                            errorMessage={errors.password?.message}
                            register={register}
                            name="password"
                        />
                        <Button
                            type="submit"
                            width="w-full"
                            containerClassName="mt-5"
                            disabled={loader}
                            value={loader ? "Loading..." : "Login"}
                        />
                    </form>
                </div>
            </div>
        </div>
    )
}
