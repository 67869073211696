function getItem(key: string): string | null {
    return localStorage.getItem(key)
}

function getItemAsync(key: string, valueToReplace?: string) {
    if (typeof valueToReplace !== "string") {
        throw new Error("Value to replacement must be a string!")
    }

    return new Promise(resolve => {
        setTimeout(() => {
            resolve(getItem(key) || valueToReplace)
        }, 0)
    })
}

function setItem(key: string, _value?: any): any {
    const value = typeof _value !== "string" ? JSON.stringify(_value) : _value

    localStorage.setItem(key, value)
    return _value
}

function setItemAsync(key: string, value: string) {
    return Promise.resolve(setItem(key, value))
}

function removeItem(key: string): string | null {
    const value = getItem(key)
    localStorage.removeItem(key)
    return value
}

export { getItem, getItemAsync, setItem, setItemAsync, removeItem }
