import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import "react-big-calendar/lib/css/react-big-calendar.css"

import "react-big-calendar/lib/addons/dragAndDrop/styles.css"

import App from "components/App"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(<App />)
