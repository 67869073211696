import React from "react"
import { Route, Redirect, RouteProps } from "react-router-dom"

interface IProps extends RouteProps {
    component: React.FC<RouteProps>
    authorized: boolean
}

export const PublicRoute: React.FC<IProps> = ({
    component: Component,
    authorized,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            exact
            render={p => {
                if (!authorized) {
                    return <Component {...p} />
                }

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const { from } = p.location.state || {
                    from: { pathname: "/" },
                }
                return <Redirect to={from} />
            }}
        />
    )
}
