import "react-notifications-component/dist/theme.css"

import { ReactNotifications } from "react-notifications-component"
import { Provider } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom"
import { PersistGate } from "redux-persist/integration/react"
import { persistor, store } from "redux/configureStore"
import { Routes } from "routing/Routes"

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ReactNotifications />
                <Router>
                    <Routes />
                </Router>
            </PersistGate>
        </Provider>
    )
}

export default App
