import * as Yup from "yup"

const UserValidation = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    email: Yup.string()
        .email("Enter a valid email address")
        .required("Email address is required"),
    accountId: Yup.string().required("Account Id is required").min(6).max(16),
})

const LoginValidation = Yup.object().shape({
    email: Yup.string()
        .email("Enter a valid email address")
        .required("Please enter email address"),
    password: Yup.string().required("Please enter password").min(6),
})

const GuardValidation = Yup.object().shape({
    userId: Yup.string().required("Please select a user"),
    gender: Yup.string().required("Please select gender").oneOf(["M", "F"]),
    address: Yup.object().shape({
        state: Yup.string().required("Please enter a valid address"),
        city: Yup.string().required("Please enter city"),
    }),
    contactNumber: Yup.string().required("Please enter a valid contact number"),
})

const CitizenshipValidation = Yup.object().shape({
    guardId: Yup.string().required("Please select a user"),
    passportNumber: Yup.string().required("Please enter passport number"),
    greenCardHolder: Yup.string().required("Please enter title"),
    greenCardExpiryDate: Yup.date().required("Please enter a valid date"),
})

const LicenseValidation = Yup.object().shape({
    license: Yup.array().of(
        Yup.object().shape({
            licenseNumber: Yup.string().required(
                "Please enter a valid license number",
            ),
            licenseTitle: Yup.string().required(
                "Please enter a valid license title",
            ),
            licenseExpiry: Yup.date().required(
                "Please enter a valid license expiry",
            ),
        }),
    ),
})

const ClientValidation = Yup.object().shape({
    name: Yup.string().required("Please enter name"),
    email: Yup.string()
        .email("Please enter a valid email")
        .required("Please enter email"),
    contact: Yup.string().required("Please enter contact number"),
})

export {
    LoginValidation,
    UserValidation,
    GuardValidation,
    LicenseValidation,
    CitizenshipValidation,
    ClientValidation,
}
