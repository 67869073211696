import React, { FC } from "react"
import { Banner } from "components/Common/Banner"
import { Sidebar } from "../Layouts/Sidebar"
import { MobileSidebar } from "../Layouts/MobileSidebar"

interface IProps {
    children: React.ReactNode
}

export const DashboardLayout: FC<IProps> = ({ children }) => {
    const [sidebarOpen, setSidebarOpen] = React.useState(false)
    return (
        <div className="min-h-screen font-jakarta">
            <div className="h-screen flex overflow-hidden bg-gray-100">
                {/* Mobile sidebar */}
                <div className="block lg:hidden">
                    <MobileSidebar
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                    />
                </div>

                {/* Static sidebar for desktop */}
                <div className="hidden lg:block">
                    <Sidebar />
                </div>
                <div className="flex flex-col w-0 flex-1 overflow-hidden">
                    <Banner setSidebarOpen={setSidebarOpen} />

                    <main className="focus:outline-none overflow-auto h-screen p-6">
                        {children}
                    </main>
                </div>
            </div>
        </div>
    )
}
