import classNames from "classnames"
import React from "react"

export interface IOptions {
    value?: string
    label: string
}

type Props = {
    placeholder: string
    label: string
    layout?: string
    errorMessage?: string
    required?: boolean
    className?: string
    name: string
    options: IOptions[]
    register: any
}

export const Select: React.FC<Props> = ({
    register,
    label,
    placeholder,
    className,
    required,
    name,
    options,
    errorMessage,
    layout,
}) => {
    return (
        <div className={layout}>
            <label
                htmlFor={label}
                className="block text-sm font-semibold tracking-wide text-gray-700">
                {label} {required && <span className="text-red-500">*</span>}
            </label>
            <select
                {...register(name)}
                placeholder={placeholder}
                className={classNames(
                    "mt-1 placeholder:text-sm  appearance-none rounded-md block w-full focus:ring-0 p-3 border border-gray-300 placeholder-gray-400 focus:outline-none sm:text-sm " +
                        className,
                    {
                        "border-red-500 focus:border-[1.5px]": !!errorMessage,
                        "ring-0 focus:border-[1px] focus:border-blue-600":
                            !errorMessage,
                    },
                )}>
                <option value="" className="text-gray-500" disabled>
                    {placeholder}
                </option>
                {options.map(option => {
                    return (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    )
                })}
            </select>
            <p className="text-red-500 text-xs font-medium tracking-wide mt-1">
                {errorMessage}
            </p>
        </div>
    )
}
