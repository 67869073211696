import React, { FC, Fragment } from "react"
import {
    ChevronDownIcon,
    MenuAlt2Icon,
    UserCircleIcon,
} from "@heroicons/react/outline"
import { Menu, Transition } from "@headlessui/react"
import { Link } from "react-router-dom"
import { useThunkDispatch } from "hooks/useThunkDispatch"
import { logOut } from "redux/actions/user"
import useAccount from "hooks/useAccount"
import { ROUTES } from "utils/constants"
import { redirectTo } from "utils/links"

type Props = {
    setSidebarOpen: (value: boolean) => void
}

export const Banner: FC<Props> = ({ setSidebarOpen }) => {
    const { user } = useAccount()

    const dispatch = useThunkDispatch()

    return (
        <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
            <button
                className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
                onClick={() => setSidebarOpen(true)}
            >
                <span className="sr-only">Open sidebar</span>
                <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex-1 flex justify-between">
                {/* <div className="flex-1 flex" /> */}
                <div />
                <div className="ml-4 flex items-center md:ml-6">
                    {/* Profile dropdown */}

                    <Menu as="div" className="ml-3 relative">
                        {({ open }) => (
                            <>
                                <Menu.Button className="max-w-xs bg-white mr-3 flex items-center text-sm rounded-full border border-logo-yellow focus:outline-none">
                                    <div className="flex items-center gap-x-2 rounded-full py-1.5 text-sm text-gray-700 px-3">
                                        <div className="h-2 w-2 rounded-full bg-green-500" />
                                        <p className="text-xs">
                                            {user?.username
                                            }
                                        </p>
                                        <ChevronDownIcon className="h-4 w-4" />
                                    </div>
                                </Menu.Button>
                                <Transition
                                    show={open}
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-100"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items
                                        static
                                        className="origin-top-right flex flex-col absolute right-0 mt-2 mr-3 w-52 rounded-md shadow-lg divide-y bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    >

                                        <Menu.Item>
                                            <button
                                                type="button"
                                                onClick={async () => {
                                                    await dispatch(logOut())
                                                    redirectTo(ROUTES.LOGIN)
                                                }}
                                                className="p-3 text-sm hover:text-logo-yellow text-gray-800 flex items-center gap-x-2"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1}
                                                    stroke="currentColor"
                                                    className="w-6 h-6"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                                                    />
                                                </svg>
                                                Logout
                                            </button>
                                        </Menu.Item>
                                    </Menu.Items>
                                </Transition>
                            </>
                        )}
                    </Menu>
                </div>
            </div>
        </div>
    )
}
