import { useFetchGuardDetails } from "hooks/useFetchGuardDetails"
import { FC } from "react"
import { AiFillWarning } from "react-icons/ai"
import { useParams } from "react-router-dom"
import { useRouter } from "hooks/useRouter"
import { IGuardDetails } from "types/index"

interface GuardState {
    guard: any // Specify that `guard` is of type IGuardDetails
}

export const GuardDetail = () => {
    const { id }: any = useParams()
    const router = useRouter()
    const guardData = router?.location?.state as GuardState

    const {
        guards: data,
        setGuards: setData,
        loading,
        // pagination,
    } = useFetchGuardDetails(id)

    return (
        <div>
            <h1 className="text-3xl text-slate-700 font-bold mb-5">
                {guardData?.guard
                    ? `Time sheet for ${guardData?.guard?.userId?.firstName} ${guardData?.guard?.userId?.lastName}`
                    : "Guard Time Sheet"}
            </h1>
            {data?.weeklyTotalHours > 40 && (
                <div className="bg-red-200 rounded-lg px-4 py-2 w-full mb-5 flex items-center justify-center">
                    <AiFillWarning className="text-red-500 mr-2" />
                    <h3 className="text-sm font-semibold text-red-500">
                        Your Weekly Duty Hours Limit exceeded! (
                        {data.weeklyTotalHours} hrs)
                    </h3>
                </div>
            )}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="bg-white shadow-md rounded-lg p-4">
                    <h3 className="text-xl font-semibold text-slate-400 mb-4">
                        Duty Hours
                    </h3>
                    <div className="flex gap-x-4 justify-between items-center ">
                        <img
                            alt="logo"
                            src="/images/duty-hours.png"
                            className="w-20 h-20"
                        />
                        <p className="font-bold text-3xl">
                            {data?.weeklyTotalHours}
                        </p>
                    </div>
                </div>
                <div className="bg-white shadow-md rounded-lg p-4">
                    <h3 className="text-xl font-semibold text-slate-400 mb-4">
                        Remaining Hours
                    </h3>
                    <div className="flex gap-x-4 justify-between items-center ">
                        <img
                            alt="logo"
                            src="/images/remaining-hours.png"
                            className="w-20 h-20"
                        />
                        <p className="font-bold text-3xl">
                            {data.remainingWeeklyHours}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
