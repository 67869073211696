import { useEffect, useState } from "react"
import { fetchGuardsWithHours } from "services/guard.services"

export const useFetchGuards = (params: any) => {
    const [guards, setGuards] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [pagination, setPagination] = useState<any>()
    useEffect(() => {
        setLoading(true)
        fetchGuardsWithHours(params)
            // fetchGuards()
            .then(resp => {
                setGuards(resp)
                setLoading(false)
                setPagination(resp?.pagination)
            })
            .catch(err => {
                setLoading(false)
            })
    }, [params?.limit, params?.page])

    return {
        guards,
        loading,
        setGuards,
        pagination,
    }
}
