import { useMemo } from "react"
import queryString from "query-string"
import {
    useParams,
    useLocation,
    useHistory,
    useRouteMatch,
} from "react-router-dom"

const STANDS = {
    LOCAL: "localhost",
    DEV: "dev",
}

// type QueryParams = Record<string, string>

// function useQueryParams(): QueryParams {
//     const { search } = useLocation()
//     return useMemo(() => {
//         const params = new URLSearchParams(search)
//         const result: QueryParams = {}
//         params.forEach((value, key) => {
//             result[key] = value
//         })
//         return result
//     }, [search])
// }

export const useRouter = () => {
    const params = useParams<{ [key: string]: string }>()
    const location = useLocation()
    const history = useHistory()
    const match = useRouteMatch()
    const { host } = document.location

    // Return our custom router object
    // Memoize so that a new object is only returned if something changes
    return useMemo(() => {
        return {
            // For convenience add push(), replace(), pathname at top level
            push: history.push,
            replace: history.replace,
            pathname: location.pathname,
            // Merge params and parsed query string into single "query" object
            // so that they can be used interchangeably.
            // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
            query: {
                ...queryString.parse(location.search),
                ...params,
            },
            params,
            // Include match, location, history objects so we have
            // access to extra React Router functionality if needed.
            match,
            location,
            history,
            host,
            isDev: host.includes(STANDS.LOCAL) || host.includes(STANDS.DEV),
        }
    }, [params, match, location, history])
}
