import React, { FC } from "react"
import { ITable } from "./Table"

export const TableHeader: FC<ITable> = ({
    columns,
    rowSelection,
    data,
    setData,
    // selectedIds,
    // setSelectedIds,
}) => {
    // console.log("From header", selectedIds)
    const handleCheck = () => {
        if (data?.length > 0) return data?.every(current => current?.select)
        else return false
    }

    const handleAllCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked
        const newData = [...data]
        newData.forEach(current => (current.select = checked))
        setData(newData)

        // if (checked) {
        //     const allIds = newData.map((item) => item._id); // Replace '_id' with the actual ID field in your data
        //     setSelectedIds(allIds);
        //   } else {
        //     setSelectedIds(null);
        //   }
    }

    const renderColumnTitle = (col: any) => {
        if (typeof col.title === "function") {
            return col.title() // Render the function directly if it's a function
        } else if (React.isValidElement(col.title)) {
            return col.title // Render if it's a valid React element (for dropdown or any JSX)
        } else {
            return <span>{col.title}</span> // Render as text if it's not a function or JSX
        }
    }

    return (
        <thead>
            <tr>
                {rowSelection && (
                    <th className="text-left pl-3 w-14">
                        <input
                            type="checkbox"
                            className="checked:border-transparent text-sm w-4 h-4"
                            onChange={handleAllCheck}
                            checked={handleCheck()}
                        />
                    </th>
                )}
                <th className="px-3 py-4 text-left text-xs font-medium text-gray-800 uppercase tracking-wider">
                    #
                </th>
                {/* {columns?.map((col, i) => (
                    <th
                        key={i}
                        scope="col"
                        className={`px-6 py-4 text-left
                        ${
                            col.key === "key" ? "flex" : ""
                        }  text-xs font-semibold text-gray-800 uppercase tracking-wider`}>
                        {col.title}
                    </th>
                ))} */}
                {columns?.map((col: any, i: any) => (
                    <th
                        key={i}
                        scope="col"
                        className={`px-6 py-4 text-left ${
                            col.key === "key" ? "flex" : ""
                        } text-xs font-semibold text-gray-800 uppercase tracking-wider`}
                    >
                        {renderColumnTitle(col)}{" "}
                        {/* Call function if it's a function */}
                    </th>
                ))}
            </tr>
        </thead>
    )
}
