import swal from "sweetalert"

export const sweetAlert = (
    callback: (confirm: boolean) => void,
    title = "Are you sure?",
    text = "Once deleted, you will not be able to recover!",
) => {
    swal({
        title,
        text,
        icon: "warning",
        className: "w-[400px]",
        buttons: {
            cancel: {
                text: "Cancel",
                visible: true,
                className: "bg-blue-500 text-white",
            },
            confirm: {
                text: "Delete",
                className: "bg-red-500 text-white",
            },
        },
        dangerMode: true,
    }).then(willDelete => {
        if (willDelete) {
            callback(true)
        } else {
            callback(false)
        }
    })
}
