import { Button } from "components/Form/Button"
import { InputField } from "components/Form/InputField"
import { useNotifications } from "hooks/useNotification"
import { FC, useEffect, useRef, useState } from "react"
import {
    Controller,
    SubmitHandler,
    UseFormWatch,
    useForm,
} from "react-hook-form"
import {
    addGuardAvailablity,
    updateGuardAvailability,
} from "services/guard.services"
import { IAvailibility, IGuard } from "types/index"
import { ROUTES } from "utils/constants"
import { redirectTo } from "utils/links"
import { useRouter } from "hooks/useRouter"

interface IProps extends IGuard<any> {
    watch: UseFormWatch<any>
}

interface DataItem {
    day: string | boolean
    dutyStart: string
    dutyEnd: string
}

type TimingObject = { [key: string]: boolean }

export const GuardAvailability: FC<IProps> = ({ guardId }) => {
    const router = useRouter()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { successMessage } = useNotifications()
    const { register, handleSubmit, setValue, watch } = useForm()
    const type = watch("type")

    useEffect(() => {
        if (router?.location?.state) {
            const { availability, fullTime, partTime } = router.location
                .state as any

            // Set the value of the radio button based on the data in router.location.state.type
            setValue("type", availability)

            // Pre-fill form fields based on the type
            if (availability === "full time") {
                fullTime.forEach((dayData: any, index: number) => {
                    // Use dayData.day to match values correctly
                    const matchingDay = [
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday",
                        "Sunday",
                    ].find(day => day === dayData.day)

                    if (matchingDay) {
                        setValue(`day[${index}].day`, matchingDay)
                    }
                })
            } else if (availability === "part time") {
                partTime.forEach((dayData: any) => {
                    const matchingDayIndex = [
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday",
                        "Sunday",
                    ].indexOf(dayData?.day)

                    if (matchingDayIndex !== -1) {
                        setValue(`timing[${matchingDayIndex}].day`, dayData.day)
                        setValue(
                            `timing[${matchingDayIndex}].dutyStart`,
                            dayData.dutyStart,
                        )
                        setValue(
                            `timing[${matchingDayIndex}].dutyEnd`,
                            dayData.dutyEnd,
                        )
                    }
                })
            }
        }
    }, [router?.location?.state, setValue])

    const onSubmit = async (data: any) => {
        try {
            if (router.location.state) {
                if (data.type === "full time") {
                    const filteredData = {
                        timing: data?.day?.filter((timing: any) => timing?.day),
                        type: data.type,
                        guardId: guardId,
                    }
                    await updateGuardAvailability(
                        filteredData,
                        (router?.location?.state as { _id: string })?._id,
                    )
                    successMessage("Guard availability updated successfully")
                    redirectTo(ROUTES.GUARDS)
                } else if (data.type === "part time") {
                    const filteredData = {
                        timing: Object.values(data.timing).filter(
                            (timing: any) =>
                                timing.day &&
                                timing.dutyStart &&
                                timing.dutyEnd,
                        ),
                        type: data.type,
                        guardId: (router?.location?.state as { _id: string })
                            ?._id,
                    }
                    await updateGuardAvailability(
                        filteredData,
                        (router?.location?.state as { _id: string })?._id,
                    )

                    successMessage("Guard availability updated successfully")
                    redirectTo(ROUTES.GUARDS)
                }

                setIsSubmitting(false)
            } else {
                if (data.type === "full time") {
                    const filteredData = {
                        timing: data?.day?.filter((timing: any) => timing?.day),
                        type: data.type,
                        guardId: guardId,
                    }
                    await addGuardAvailablity(filteredData, guardId)
                    successMessage("Guard availability added successfully")
                    redirectTo(ROUTES.GUARDS)
                } else if (data.type === "part time") {
                    const filteredData = {
                        timing: data.timing.filter(
                            (timing: any) =>
                                timing.day &&
                                timing.dutyStart &&
                                timing.dutyEnd,
                        ),
                        type: data.type,
                        guardId: guardId,
                    }

                    await addGuardAvailablity(filteredData, guardId)
                    successMessage("Guard availability added successfully")
                    redirectTo(ROUTES.GUARDS)
                }

                setIsSubmitting(false)
            }
        } catch (err) {
            setIsSubmitting(false)
        }
    }

    return (
        <div className="bg-white rounded-md shadow-lg overflow-hidden">
            <div className="bg-gray-50 border-b px-7 py-4">
                <p className="font-semibold text-gray-700 tracking-wide">
                    Guard info
                </p>
            </div>
            <div className="p-7">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex items-center gap-4">
                        <label
                            htmlFor="full time"
                            className="text-gray-700 font-semibold"
                        >
                            <input
                                id="full time"
                                type="radio"
                                value="full time"
                                {...register("type")}
                                required
                            />
                            <span className="ml-2">Full Time</span>
                        </label>
                        <label
                            htmlFor="part time"
                            className="text-gray-700 font-semibold"
                        >
                            <input
                                // name="type"
                                type="radio"
                                value="part time"
                                {...register("type")}
                                required
                            />
                            <span className="ml-2">Part Time</span>
                        </label>
                    </div>

                    {type == "full time" ? (
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
                            {[
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday",
                                "Saturday",
                                "Sunday",
                            ].map((day, index) => (
                                <div
                                    key={index}
                                    className=" flex items-center gap-x-12"
                                >
                                    <label
                                        className="w-20"
                                        htmlFor={`timing[${index}].day`}
                                    >
                                        {day}
                                    </label>
                                    <div key={index} className="relative">
                                        <label className="switch">
                                            <input
                                                // name={"day"}
                                                type="checkbox"
                                                {...register(
                                                    `day[${index}].day`,
                                                )}
                                                value={day}
                                            />
                                            <span className="slider" />
                                        </label>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : type === "part time" ? (
                        // create a toggle button which will show availability for each day of the week and time
                        <>
                            <div>
                                <div>
                                    {[
                                        "Monday",
                                        "Tuesday",
                                        "Wednesday",
                                        "Thursday",
                                        "Friday",
                                        "Saturday",
                                        "Sunday",
                                    ].map((day, index) => (
                                        <div
                                            className="flex items-center justify-between mt-2"
                                            key={index}
                                        >
                                            <div className=" flex items-center gap-x-12">
                                                <label
                                                    className="w-20"
                                                    htmlFor={`timing[${index}].day`}
                                                >
                                                    {day}
                                                </label>
                                                <div className="relative">
                                                    <label className="switch">
                                                        <input
                                                            // name={"day"}
                                                            type="checkbox"
                                                            {...register(
                                                                `timing[${index}].day`,
                                                            )}
                                                            value={day}
                                                        />
                                                        <span className="slider" />
                                                    </label>
                                                </div>
                                            </div>

                                            <div>
                                                <label
                                                    className=""
                                                    htmlFor={`timing[${index}].dutyStart`}
                                                >
                                                    From
                                                </label>
                                                <input
                                                    type="time"
                                                    // name={`timing[${index}].dutyStart`}
                                                    {...register(
                                                        `timing[${index}].dutyStart`,
                                                    )}
                                                    className="px-3 py-2 focus:outline-none border border-gray-300 rounded-md"
                                                />
                                                <label
                                                    htmlFor={`timing[${index}].dutyEnd`}
                                                >
                                                    To
                                                </label>
                                                <input
                                                    type="time"
                                                    // name={`timing[${index}].dutyEnd`}
                                                    {...register(
                                                        `timing[${index}].dutyEnd`,
                                                    )}
                                                    className="px-3 py-2 focus:outline-none border border-gray-300 rounded-md"
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    ) : null}

                    <Button
                        type="submit"
                        containerClassName="mt-3"
                        disabled={isSubmitting}
                        value="Save"
                    />
                    {/* <input type="submit" value="Save" /> */}
                </form>
            </div>
        </div>
    )
}
