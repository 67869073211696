import React, { Dispatch, FC, Fragment, SetStateAction } from "react"
import { XIcon } from "@heroicons/react/outline"
import { Dialog, Transition } from "@headlessui/react"
import { Sidebar } from "./Sidebar"

type Props = {
    sidebarOpen: boolean
    setSidebarOpen: Dispatch<SetStateAction<boolean>>
}

export const MobileSidebar: FC<Props> = ({ sidebarOpen, setSidebarOpen }) => {
    return (
        <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed w-80 inset-0 flex z-40"
                open={sidebarOpen}
                onClose={setSidebarOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full">
                    <div className="flex-1 flex flex-col max-w-xs w-full h-full ">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-in-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in-out duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">
                            <div className="absolute top-0 right-0 -mr-12 pt-2">
                                <button
                                    className="ml-1 bg-gray-100 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                    onClick={() => setSidebarOpen(false)}>
                                    <span className="sr-only">
                                        Close sidebar
                                    </span>
                                    <XIcon
                                        className="h-5 w-5 text-black"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                        </Transition.Child>
                        <Sidebar />
                    </div>
                </Transition.Child>
            </Dialog>
        </Transition.Root>
    )
}
