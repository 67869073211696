import React, { FC, useState } from "react"
import { FaSpinner } from "react-icons/fa"
import { IColumn, ITable } from "./Table"
import { Paginate } from "../Paginate"

export const TableBody: FC<ITable> = ({
    data,
    setData,
    columns,
    rowSelection,
    loadingData,
    // setSelectedIds,
    // selectedIds,
}) => {
    // px-6 py-4 text-sm font-light text-logo-black tracking-wide whitespace-nowrap row classes
    const renderTableColumnData = (col: IColumn, data: Array<any>) => {
        const column =
            col.dbColName !== undefined &&
            data[col.dbColName as keyof typeof data]

        if (
            checkProperty({ obj: col, key: "render" }) &&
            !checkProperty({ obj: col, key: "dbColName" })
        ) {
            return (
                <td
                    key={`${col.key}`}
                    className="px-6 py-4 text-sm font-light text-logo-black tracking-wide witespace-nowrap"
                >
                    {col.render(data)}
                </td>
            )
        } else if (
            checkProperty({ obj: col, key: "render" }) &&
            checkProperty({ obj: col, key: "dbColName" })
        ) {
            return (
                <td
                    key={`${col.key}`}
                    className="px-4 py-4 text-sm font-light text-logo-black tracking-wide whitespace-nowrap"
                >
                    {col.render(column)}
                </td>
            )
        } else {
            return (
                <td
                    key={`${col.key}`}
                    className="px-4 py-4 text-sm font-light text-logo-black tracking-wide whitespace-nowrap"
                >
                    {column}
                </td>
            )
        }
    }

    const handleSingleCheck = (
        event: React.ChangeEvent<HTMLInputElement>,
        id: string,
    ) => {
        const checked = event.target.checked
        const newData = [...data]
        newData.forEach(current => {
            if (current._id === id) {
                current.select = checked
            }
        })
        setData(newData)

        // if (checked) {
        //     setSelectedIds((prevSelectedIds: any) => [...prevSelectedIds, id])
        // } else {
        //     setSelectedIds((prevSelectedIds: any) =>
        //         prevSelectedIds.filter((selectedId: any) => selectedId !== id),
        //     )
        // }
    }

    //  {data && data?.length > 0 && (
    //         <div className="flex items-center justify-between mb-2">
    //         {/* <span className="text-gray-600 text-xs">
    //             results({data?.data?.length})
    //         </span> */}
    //         <Paginate
    //             data={data}
    //             itemsPerPage={2}
    //             setCurrentItems={setCurrentItems}
    //         />
    //     </div>
    // )}

    return (
        <>
            <tbody className="bg-white divide-y divide-gray-200">
                <tr>
                    {(data?.length === 0 || !data) && !loadingData && (
                        <td colSpan={10} className="text-center p-5">
                            <svg
                                className="w-16 h-16 mx-auto text-gray-600"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={1.2}
                                    d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                                />
                            </svg>
                            <p className="text-gray-900 font-medium mt-1">
                                No data found
                            </p>
                        </td>
                    )}
                    {loadingData && data?.length === 0 && (
                        <td colSpan={8} className="p-14">
                            <FaSpinner className="h-10 w-10 mx-auto animate-spin" />
                        </td>
                    )}
                </tr>
                {data?.length !== 0 &&
                    data?.map((d, index) => (
                        <tr
                            key={index}
                            className={`${
                                index % 2 === 0
                                    ? "bg-logo-yellow/5"
                                    : "bg-white "
                            }  hover:bg-logo-yellow/10`}
                        >
                            {rowSelection && (
                                <td className="pl-3">
                                    <input
                                        type="checkbox"
                                        checked={d.select}
                                        className="rounded-lg w-4 h-4 text-sm checked:border-transparent"
                                        onChange={event =>
                                            handleSingleCheck(event, d._id)
                                        }
                                    />
                                </td>
                            )}
                            <td className="px-3 py-4 text-sm font-light">
                                {++index}
                            </td>
                            {columns.map((col: any) =>
                                renderTableColumnData(col, d),
                            )}
                        </tr>
                    ))}
            </tbody>
        </>
    )
}

const checkProperty = ({ obj, key }: { obj: object; key: string }) => {
    return Object.prototype.hasOwnProperty.call(obj, key)
}
