import { FC, useState } from "react"
import { PlusIcon } from "@heroicons/react/outline"
import { Modal } from "components/Common/Modal"
import { ReactStars } from "components/Common/ReactStars"
import { Table } from "components/Common/Table/Table"
import { Button } from "components/Form/Button"
import { useFetchGuards } from "hooks/useFetchGuards"
import { useNotifications } from "hooks/useNotification"
import { useRouter } from "hooks/useRouter"
import {
    addGuardRating,
    deleteGuard,
    updateGuardRating,
} from "services/guard.services"
import { IGuardDetails, IUser } from "types/index"
import { ROUTES } from "utils/constants"
import { DefaultActions } from "components/Common/Table/defaultAction"
import { sweetAlert } from "utils/sweetAlert"
import { BsFillEyeFill } from "react-icons/bs"
import { Pagination } from "components/Common/Table/Paginations"

export const GuardsPage: FC = () => {
    const router = useRouter()

    const [page, setPage] = useState(1)
    const pageSize = 10

    const columns = [
        {
            key: "name",
            title: "Full Name",
            dbColName: "userId",
            render: (user: IUser) => {
                return <p>{user?.username}</p>
            },
        },
        {
            key: "email",
            title: "Email",
            dbColName: "userId",
            render: (user: IUser) => <p>{user?.email}</p>,
        },
        {
            key: "type",
            title: "Type",
            dbColName: "type",
        },
        {
            key: "number",
            title: "Contact Number",
            dbColName: "contactNumber",
        },
        {
            key: "rating",
            title: "Rating",
            render: (guard: IGuardDetails) => {
                const rating = guard?.performanceEvaluation
                return (
                    <button
                        type="button"
                        onClick={() => {
                            setRatingModal(true)
                            setRatings({
                                ...ratings,
                                guardId: guard._id as string,
                                behavior: rating?.behavior,
                                dutyTiming: rating?.dutyTiming,
                                reportSubmission: rating?.reportSubmission,
                                id: guard?.performanceEvaluation?._id,
                            })
                            setForRating(
                                rating !== null ? "Update" : "New Rate",
                            )
                        }}
                        className="text-yellow-600"
                    >
                        <span>&#9733;</span>
                        {rating !== null ? "Update Rating" : "Add Rating"}
                    </button>
                )
            },
        },
        {
            key: "action",
            title: "Action",
            render: (guard: IGuardDetails) => (
                <DefaultActions
                    onDelete={() => handleDelete(guard._id)}
                    onEdit={() =>
                        router.push(
                            {
                                pathname: ROUTES.ADD_GUARD,
                                state: guard,
                            },
                            ROUTES.ADD_GUARD,
                        )
                    }
                />
            ),
        },
        {
            key: "View",
            title: "View",
            render: (guard: IGuardDetails) => (
                <div className="text-center">
                    <BsFillEyeFill
                        className="text-blue-500 cursor-pointer"
                        onClick={() =>
                            router.push({
                                pathname: `/guard/${guard._id}`,
                                state: { guard },
                            })
                        }
                    />
                </div>
            ),
        },
    ]
    const [ratings, setRatings] = useState<any>({
        guardId: "",
        dutyTiming: 0,
        behavior: 0,
        reportSubmission: 0,
        id: "",
    })
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [ratingModal, setRatingModal] = useState<boolean>(false)
    const [forRating, setForRating] = useState<string>("")

    const { successMessage, errorMessage } = useNotifications()
    const {
        guards: data,
        setGuards: setData,
        loading,
        pagination,
    } = useFetchGuards({
        page: page,
        limit: pageSize,
    })
    const handleDelete = async (id?: string) => {
        sweetAlert((confirm: boolean) => {
            if (confirm) {
                deleteGuard(id as string).then(() => {
                    const newData = data.filter(
                        (guard: any) => guard._id !== id,
                    )
                    setData(newData)
                    successMessage("User deleted successfully!")
                })
            }
        })
    }

    const handleAddRating = async () => {
        try {
            if (forRating === "Update") {
                await updateGuardRating(ratings)
                    .then(() => {
                        setIsSubmitting(false)
                        setRatingModal(false)
                        successMessage("Rating updated successfully")
                    })
                    .catch(error => console.log(error))
            } else {
                const resp = await addGuardRating(ratings)
                if (resp?.status === 200) {
                    setIsSubmitting(false)
                    setRatingModal(false)
                    successMessage("Rating added successfully")
                } else {
                    errorMessage("Something went wrong please try later")
                }
            }
        } catch (err) {
            setIsSubmitting(false)
        }
    }

    return (
        <div className="flex flex-col gap-3">
            <div className="text-center flex items-center justify-end">
                <button
                    type="button"
                    onClick={() => router.push(ROUTES.ADD_GUARD)}
                    className="flex transition duration-500 hover:bg-yellow-600 items-center gap-1.5 rounded-md text-sm uppercase shadow font-semibold justify-center bg-logo-yellow w-32 py-2.5 text-white"
                >
                    <PlusIcon className="h-3.5 w-3.5" /> Add Guard
                </button>
            </div>
            <Table
                rowSelection
                data={data}
                loadingData={loading}
                columns={columns}
                setData={setData}
            />

            <Pagination
                currentPage={page}
                setCurrentPage={setPage}
                itemsPerPage={pagination?.itemPerPage}
                totalItems={pagination?.totalResult}
                showingData={data}
            />

            <Modal
                showModal={ratingModal}
                setShowModal={setRatingModal}
                title="Add Guard Rating"
                width="w-1/4"
            >
                <div className="grid grid-cols-1 place-items-center">
                    <div className="flex flex-col">
                        <p className="text-gray-700 text-sm tracking-wide font-jakarta">
                            Duty Timing
                        </p>
                        <ReactStars
                            onClick={rating =>
                                setRatings({ ...ratings, dutyTiming: rating })
                            }
                            value={ratings.dutyTiming}
                        />
                    </div>
                    <div className="flex flex-col mt-6">
                        <p className="text-gray-700 text-sm tracking-wide font-jakarta">
                            Behaviour
                        </p>
                        <ReactStars
                            onClick={rating =>
                                setRatings({ ...ratings, behavior: rating })
                            }
                            value={ratings.behavior}
                        />
                    </div>
                    <div className="flex flex-col mt-6">
                        <p className="text-gray-700 text-sm tracking-wide font-jakarta">
                            Report Submission
                        </p>
                        <ReactStars
                            onClick={rating =>
                                setRatings({
                                    ...ratings,
                                    reportSubmission: rating,
                                })
                            }
                            value={ratings.reportSubmission}
                        />
                    </div>
                </div>
                <Button
                    type="button"
                    disabled={isSubmitting}
                    containerClassName="mt-6"
                    onClick={handleAddRating}
                    width="w-full"
                    value={forRating === "Update" ? "Update" : `Save`}
                />
            </Modal>
        </div>
    )
}
