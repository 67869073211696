import React from "react"
import classNames from "classnames"
import { UseFormRegister, useFormContext } from "react-hook-form"
import { usePlacesWidget } from "react-google-autocomplete"

type Props = {
    type: string
    placeholder: string
    label: string
    layout?: string
    errorMessage?: string
    required?: boolean
    className?: string
    name: string
    disabled?: boolean
    register: UseFormRegister<any>
    onPlaceSuggetions?: any
    placesSuggetions?: any
    onChange?: any
    setFormattedAddress?: any
    setLatitude?: any
    value?: any
}

export const InputField: React.FC<Props> = ({
    register,
    label,
    placeholder,
    className,
    required,
    type,
    disabled,
    name,
    errorMessage,
    layout,
    onPlaceSuggetions,
    placesSuggetions,
    onChange,
    setFormattedAddress,
    setLatitude,
    value,
}) => {
    const formContext = useFormContext()
    const { ref }: any = usePlacesWidget({
        apiKey: "AIzaSyCClbPH7R_DSWEhaAM9j-nTBK1w90rUCo8",
        onPlaceSelected: (place: any) => {
            // setFormattedAddress(place.formatted_address)
            setFormattedAddress(place.formatted_address)
            setLatitude({
                lat: place?.geometry?.location.lat(),
                lng: place?.geometry?.location.lng(),
            })
            onPlaceSuggetions?.setIsPlaceSelected(true)
        },
        options: {
            types: ["establishment", "geocode"], // Include different types as needed
        },
    })
    const formRef = formContext && formContext.register(name)
    return (
        <div className={layout}>
            <label
                htmlFor={label}
                className="block text-sm font-semibold tracking-wide text-gray-700 "
            >
                {label} {required && <span className="text-red-500">*</span>}
            </label>
            <input
                type={type}
                {...register(name)}
                disabled={disabled}
                placeholder={placeholder}
                className={classNames(inputStyles.input + className, {
                    [inputStyles.error]: !!errorMessage,
                    [inputStyles.focused]: !errorMessage,
                })}
                {...(onPlaceSuggetions?.placesSuggetions || placesSuggetions
                    ? {
                        ref: (e: any) => {
                            formRef && formRef.ref(e)
                            ref.current = e
                        },
                    }
                    : {})}
                onChange={onChange}
                value={value}
            // onFocus={onFocus}
            />
            <p className={inputStyles.errorMessage}>{errorMessage}</p>
        </div>
    )
}

export const inputStyles = {
    input: "mt-1 placeholder:text-sm rounded-md text-gray-800 font-jakarta appearance-none block w-full focus:ring-0 p-3 border border-gray-300 placeholder-gray-400 focus:outline-none sm:text-sm ",
    error: "border-red-500 focus:border-[1.5px]",
    focused: "ring-0 focus:border-[1px] focus:border-logo-yellow",
    errorMessage: "text-red-500 text-xs font-medium tracking-wide mt-1",
}
