export const Roles = {
    ADMIN: "admin",
    SCHEDULER: "scheduler",
    SUPERVISOR: "supervisor",
    GUARD: "guard",
}

export const ROUTES = {
    LOGIN: "/login",

    DASHBOARD: "/",
    ABOUT: "/about",

    // Admin
    USERS: "/users",
    GUARDS: "/guards",
    GUARD_DETAIL: "/guard/:id",
    ADD_GUARD: "/add/guard",
    EDIT_GUARD: "/edit/guard/:id",
    SITES: "/sites",
    ADDSITE: "/addSite",

    // Client
    CLIENTS: "/clients",
    ADD_CLIENT: "/add/client",
    EDIT_CLIENT: "/edit/client/:id",

    ADD_SCHEDULING: "/add/schedule",
    EDIT_SCHEDULING: "/edit/schedule/:id",
    SCHEDULING: "/scheduling",

    SUPERVISOR: "/supervisor",
    Issues: "/issues",
}
