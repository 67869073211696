import React from "react"
import { Switch } from "react-router-dom"
import {
    LoginPage,
    AddGuard,
    HomePage,
    UsersPage,
    GuardsPage,
    GuardDetail,
} from "pages"
import { ProtectedRoute, PublicRoute } from "routing"
import { DashboardLayout } from "components/Layouts/DashboardLayout"
import { ROUTES, Roles } from "utils/constants"
import useAccount from "hooks/useAccount"

export const Routes: React.FC = () => {
    const { user, isLoggedIn } = useAccount()

    const userRoles = user ? [user.role] : []

    return (
        <Switch>
            <PublicRoute
                path={ROUTES.LOGIN}
                authorized={isLoggedIn}
                component={LoginPage}
            />
            <DashboardLayout>
                <ProtectedRoute
                    path={ROUTES.DASHBOARD}
                    authorized={isLoggedIn}
                    component={HomePage}
                />
                <ProtectedRoute
                    authorized={isLoggedIn}
                    path={ROUTES.USERS}
                    userRoles={userRoles}
                    permittedRoles={[Roles.ADMIN]}
                    component={UsersPage}
                />
                <ProtectedRoute
                    authorized={isLoggedIn}
                    path={ROUTES.GUARDS}
                    userRoles={userRoles}
                    permittedRoles={[Roles.ADMIN]}
                    component={GuardsPage}
                />
                <ProtectedRoute
                    authorized={isLoggedIn}
                    path={ROUTES.GUARD_DETAIL}
                    userRoles={userRoles}
                    permittedRoles={[Roles.ADMIN]}
                    component={GuardDetail}
                />
                <ProtectedRoute
                    authorized={isLoggedIn}
                    path={ROUTES.ADD_GUARD}
                    userRoles={userRoles}
                    permittedRoles={[Roles.ADMIN]}
                    component={AddGuard}
                />






            </DashboardLayout>
        </Switch>
    )
}
