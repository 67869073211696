import { FC, Fragment } from "react"
import { FaEdit } from "react-icons/fa"
import { BsFillTrash3Fill } from "react-icons/bs"

interface Props {
    onDelete: () => void
    onEdit?: () => void
}

export const DefaultActions: FC<Props> = ({ onDelete, onEdit }) => {
    return (
        <Fragment>
            {onEdit && (
                <button
                    onClick={onEdit}
                    className="text-logo-yellow mr-1.5 focus:outline-none"
                >
                    <FaEdit className="h-5 w-5" />
                </button>
            )}
            <button
                onClick={onDelete}
                className="text-red-500 focus:outline-none"
            >
                <BsFillTrash3Fill className="h-5 w-5" />
            </button>
        </Fragment>
    )
}
