import axiosInstance from "utils/axiosInstance"
import { ILoginForm, IUser } from "types/index"

const getUsers = (params: any) =>
    axiosInstance.get("users", { params }).then(resp => resp)

const loginUser = (credentials: ILoginForm) =>
    axiosInstance.post("/auth/login", credentials).then(resp => resp)

const deleteUser = (ids: string[]) =>
    axiosInstance.post("/users/delete", ids).then(resp => resp)

const logoutUser = () => axiosInstance.get("/auth/logout").then(resp => resp)

const updateUser = (user: IUser, id?: string) =>
    axiosInstance.patch(`/users/${id}`, user).then(resp => resp)

const createUser = (user: IUser) => axiosInstance.post("/users", user)

export { getUsers, loginUser, deleteUser, updateUser, createUser, logoutUser }
