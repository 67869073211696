import { FC, useEffect, useState } from "react"
import { InputField } from "components/Form/InputField"
import { IOptions, Select } from "components/Form/Select"
import { SubmitHandler } from "react-hook-form"
import { getUsers } from "services/user.services"
import { IGuard, IGuardDetails, IUser } from "types/index"
import { addGuardInfo, updateGuardInfo } from "services/guard.services"
import { Roles } from "utils/constants"
import { TABS } from "pages/Guard/AddGuard"
import { useNotifications } from "hooks/useNotification"
import { Button } from "components/Form/Button"
import { useRouter } from "hooks/useRouter"

export const GuardInfo: FC<IGuard<IGuardDetails>> = ({
    setComponent,
    setGuardId,
    register,
    handleSubmit,
    errors,
}) => {
    const router = useRouter()
    const [users, setUsers] = useState<IOptions[]>([])
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [, setIsEditing] = useState<boolean>(false)
    const [click, setClick] = useState<"save" | "saveNext">("save")
    const { successMessage } = useNotifications()
    useEffect(() => {
        getUsers({}).then(resp => {
            if (router.location.state) {
                const filtered = resp?.data?.data
                    .filter((user: IUser) => user.role === Roles.GUARD)
                    .map((user: IUser) => ({
                        value: user?._id,
                        label: user?.username,
                    }))
                const selectedGuard = filtered.filter((item: any) => {
                    if (
                        item.value ===
                        (router.location.state as { userId: { _id: string } })
                            .userId._id
                    ) {
                        return {
                            value: item?.value,
                            label: item?.firstName + " " + item?.lastName,
                        }
                    }
                })
                setUsers(selectedGuard)
            } else {
                const filtered = resp?.data?.data
                    .filter((user: IUser) => user.role === Roles.GUARD)
                    .map((user: IUser) => ({
                        value: user?._id,
                        label: user?.username,
                    }))
                setUsers(filtered)
            }
        })
    }, [])

    const onSubmit: SubmitHandler<IGuardDetails> = async data => {
        setIsSubmitting(true)
        try {
            if (router?.location?.state) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const guardId = router?.location?.state?._id
                await updateGuardInfo(data, guardId)
                    .then(resp => {
                        setIsSubmitting(false)
                        successMessage("Guard info updated successfully")
                        setIsEditing(true)
                        if (click === "saveNext") setComponent(TABS.LICENSE)
                        setGuardId && setGuardId(resp.data._id)
                    })
                    .catch(error => {
                        console.log(error)
                        console.log(error?.response?.data)
                    })
            } else {
                console.log("data", data)
                const resp = await addGuardInfo(data)
                setIsSubmitting(false)
                successMessage("Guard info added successfully")
                setIsEditing(true)
                if (click === "saveNext") setComponent(TABS.LICENSE)
                setGuardId && setGuardId(resp.data._id)
            }
        } catch (e) {
            setIsSubmitting(false)
        }
    }

    return (
        <div className="bg-white rounded-md shadow-lg overflow-hidden">
            <div className="bg-gray-50 border-b px-7 py-4">
                <p className="font-semibold text-gray-700 tracking-wide">
                    Guard info
                </p>
            </div>
            <div className="p-7">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex items-center gap-4">
                        <label
                            htmlFor="permanent"
                            className="text-gray-700 font-semibold"
                        >
                            <input
                                id="permanent"
                                type="radio"
                                value="permanent"
                                {...register("type")}
                                required
                            />
                            <span className="ml-2">Permanent</span>
                        </label>
                        <label
                            htmlFor="temporary"
                            className="text-gray-700 font-semibold"
                        >
                            <input
                                id="temporary"
                                type="radio"
                                value="temporary"
                                {...register("type")}
                                required
                            />
                            <span className="ml-2">Part Time</span>
                        </label>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
                        <Select
                            label="Select guard"
                            name="userId"
                            options={users.map((user: any) => {
                                return {
                                    value: user?.value.toString(),
                                    label: user?.label,
                                }
                            })}
                            errorMessage={errors.userId?.message}
                            register={register}
                            required
                            placeholder="Select guard"
                        />

                        <Select
                            label="Select gender"
                            name="gender"
                            options={[
                                { value: "M", label: "Male" },
                                { value: "F", label: "Female" },
                            ]}
                            register={register}
                            errorMessage={errors.gender?.message}
                            required
                            placeholder="Select gender"
                        />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
                        <InputField
                            type="text"
                            placeholder="Address line 1"
                            label="Address line 1"
                            errorMessage={errors.address?.state?.message}
                            name="address.addressLine1"
                            register={register}
                        />
                        <InputField
                            type="text"
                            placeholder="Address line 2"
                            label="Address line 2"
                            errorMessage={errors.address?.city?.message}
                            name="address.addressLine2"
                            register={register}
                        />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
                        {/*<InputField*/}
                        {/*    type="text"*/}
                        {/*    placeholder="Street"*/}
                        {/*    label="Street"*/}
                        {/*    name="address.street"*/}
                        {/*    register={register}*/}
                        {/*/>*/}
                        <InputField
                            type="text"
                            placeholder="City"
                            required
                            label="City"
                            errorMessage={errors.address?.city?.message}
                            name="address.city"
                            register={register}
                        />
                        <InputField
                            type="text"
                            placeholder="State"
                            label="State"
                            errorMessage={errors.address?.state?.message}
                            name="address.state"
                            register={register}
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-4 mt-3">
                        <InputField
                            type="text"
                            placeholder="Zip Code"
                            label="Zip Code"
                            name="address.zipCode"
                            register={register}
                        />
                        <InputField
                            type="text"
                            placeholder="Contact Number"
                            required
                            errorMessage={errors.contactNumber?.message}
                            label="Contact Number"
                            name="contactNumber"
                            register={register}
                        />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
                        <InputField
                            type="text"
                            placeholder="Emergency Contact Number"
                            label="Emergency Contact Number"
                            name="emergencyContactNumber"
                            register={register}
                        />
                    </div>
                    <div className="flex items-center gap-2 mt-4">
                        <Button
                            type="submit"
                            disabled={isSubmitting && click === "save"}
                            onClick={() => setClick("save")}
                            value="Save"
                        />

                        <Button
                            type="submit"
                            disabled={isSubmitting && click === "saveNext"}
                            onClick={() => setClick("saveNext")}
                            bg="bg-logo-black"
                            value={"Save & Next"}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}
