import { IClient } from "types/index"
import axiosInstance from "utils/axiosInstance"

const getClients = (params: any) =>
    axiosInstance.get("clients", { params }).then(resp => resp.data)

const addClient = (data: IClient) =>
    axiosInstance.post("clients", data).then(resp => resp.data)

const deleteClient = (clientIds: string[]) =>
    axiosInstance.post("/clients/delete", clientIds).then(resp => resp.data)

const updateClient = (data: IClient, clientId?: string) =>
    axiosInstance.put(`/clients/${clientId}`, data).then(resp => resp.data)
const getAllIssue = () => axiosInstance.get("/issues").then(resp => resp.data)

export { getClients, addClient, deleteClient, updateClient, getAllIssue }
