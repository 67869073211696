import { FC, useEffect, useState } from "react"
import React from "react"
import { createUser, deleteUser, getUsers, updateUser } from "services"
import { ApiResponse, IUser } from "types"
import { Table } from "components/Common/Table/Table"
import { DefaultActions } from "components/Common/Table/defaultAction"
import { Modal } from "components/Common/Modal"
import { SubmitHandler, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { UserValidation } from "utils/validation"
import { InputField } from "components/Form/InputField"
import { Select } from "components/Form/Select"
import { PlusIcon, TrashIcon } from "@heroicons/react/outline"
import { sweetAlert } from "utils/sweetAlert"
import { Button } from "components/Form/Button"


import { useNotifications } from "hooks/useNotification"
import { Pagination } from "components/Common/Table/Paginations"
import axiosInstance from "utils/axiosInstance"

export const UsersPage: FC = () => {
    const [pagination, setPagination] = useState<any>(null)
    const [page, setPage] = useState(1)
    const [isSubscriber, setIsSubscriber] = useState('');
    const [password, setPassword] = useState('');


    const pageSize = 10

    const columns = [
        { key: "username", title: "Username", dbColName: "username" },
        { key: "email", title: "email", dbColName: "email" },
        // { key: "username", title: "username", dbColName: "username" },
        { key: "accountId", title: "Account Id", dbColName: "accountId" },
        {
            key: "subscriber", title: "Subscriber",
            render: (users: any) => {
                return (< input
                    type="checkbox"
                    checked={users?.subscriber}
                    onChange={(e) => toggleSubscriber(e, users?._id)}
                    id="subscriber-switch"
                    className="switch-checkbox"
                />)
            }

        },

        {
            key: "actions",
            title: "actions",
            render: (user: IUser) => (
                <DefaultActions
                    onDelete={() => handleDelete(user._id)}
                    onEdit={() => onEdit(user)}
                />
            ),
        },
    ]

    const {
        handleSubmit,
        register,
        reset,
        setValue,
        formState: { errors, isSubmitting },
    } = useForm<IUser>({
        resolver: yupResolver(UserValidation),
    })

    const [users, setUsers] = useState<IUser[]>([])
    const [showModal, setShowModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [error, setError] = useState<string>("")

    const { successMessage } = useNotifications()

    const toggleSubscriber = (event: React.ChangeEvent<HTMLInputElement>, data: any) => {
        const randomString = Math.floor(10000 + Math.random() * 90000).toString();
        setIsSubscriber(randomString)
        axiosInstance.post("/users/status", {
            'subscriber': event.target.checked,
            'id': data
        })

    }
    useEffect(() => {
        setLoading(true)
        getUsers({
            page: page,
            limit: pageSize,
        })
            .then((resp: any) => {
                setUsers(resp?.data?.data)
                setPagination(resp?.data?.pagination)
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }, [page, pageSize, isSubscriber])

    const handleDelete = (id?: string) => {
        sweetAlert((confirm: boolean) => {
            if (confirm) {
                deleteUser([id as string]).then(() => {
                    const updated = users.filter(user => user._id !== id)
                    setUsers(updated)
                    successMessage("User deleted successfully!")
                })
            }
        })
    }

    const handleBulkDelete = () => {
        sweetAlert((confirm: boolean) => {
            if (confirm) {
                const ids = users
                    .filter(user => user.select)
                    .map(user => user._id)
                deleteUser(ids as string[]).then(() => {
                    const updated = users.filter(user => !user.select)
                    setUsers(updated)
                    successMessage("Users deleted successfully!")
                })
            }
        })
    }

    const onEdit = (user: IUser) => {
        console.log(user)
        setIsEditing(true)
        setShowModal(true)
        setValue("_id", user._id)
        setValue("email", user.email)
        setValue("username", user.username)
        setValue("role", user.role)
        setValue('accountId', user.accountId)

    }

    const onSubmit: SubmitHandler<IUser> = data => {
        if (isEditing) {
            delete data.select
            const selectedUser = data._id
            delete data._id
            updateUser(data, selectedUser).then((resp: ApiResponse<IUser>) => {
                const updated = users.map(user =>
                    user._id === selectedUser
                        ? { ...resp.data, select: false }
                        : user,
                )
                setUsers(updated)
                successMessage("User updated successfully!")
                setShowModal(false)
                reset()
            })
        } else {
            createUser(data)
                .then((resp: ApiResponse<IUser>) => {
                    setUsers([...users, resp.data])
                    successMessage("User created successfully!")
                    setShowModal(false)
                    reset()
                })
                .catch(error => {
                    setError(error.response.data)
                })
        }
    }

    const generateRandomPassword = () => {
        const characters = '0123456789';
        const length = 8; // Define the length of the generated password
        let password = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            password += characters.charAt(randomIndex);
        }

        return password;
    }
    useEffect(() => {
        const generatedPassword = generateRandomPassword();
        setPassword(generatedPassword);
    }, [showModal]);

    const shouldBeDisabled = users.some(user => user.select)

    const label = isEditing ? "Update User" : "Add User"

    return (
        <div className="flex flex-col gap-3">
            <div className="text-center flex items-center justify-between flex-row-reverse">
                <button
                    type="button"
                    onClick={() => setShowModal(true)}
                    className="flex transition duration-500 hover:bg-yellow-600 items-center gap-1.5 rounded-md text-sm uppercase shadow font-semibold justify-center bg-logo-yellow w-32 py-2.5 text-white"
                >
                    <PlusIcon className="h-3.5 w-3.5" /> Add user
                </button>
                <button
                    type="button"
                    onClick={handleBulkDelete}
                    disabled={!shouldBeDisabled}
                    className="flex transition duration-500 hover:bg-red-600 disabled:bg-red-300 disabled:cursor-not-allowed items-center gap-1.5 rounded-md text-sm uppercase shadow font-semibold justify-center bg-red-500 w-36 py-2.5 text-white"
                >
                    <TrashIcon className="h-4 w-4" /> Bulk delete
                </button>
            </div>
            <Table
                rowSelection
                data={users}
                loadingData={loading}
                setData={setUsers}
                columns={columns}
            />
            <Pagination
                currentPage={page}
                setCurrentPage={setPage}
                itemsPerPage={pagination?.itemPerPage}
                totalItems={pagination?.totalResult}
                showingData={users}
            />
            <Modal
                showModal={showModal}
                setShowModal={setShowModal}
                setIsEditing={setIsEditing}
                reset={reset}
                width="w-full md:w-1/2 lg:w-1/3"
                title={label}
            >
                {error && <p className="mb-3 text-red-500">{error}</p>}
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex flex-col gap-3"
                >
                    <InputField
                        register={register}
                        name="username"
                        type="text"
                        errorMessage={errors.username?.message}
                        placeholder="Enter username"
                        required
                        label="username"
                    />
                    <InputField
                        register={register}
                        name="email"
                        type="email"
                        errorMessage={errors.email?.message}
                        placeholder="Enter email address"
                        required
                        label="Email"
                    />
                    <Select
                        register={register}
                        name="role"
                        label="Role"
                        placeholder="Select role"
                        required
                        options={[

                            {
                                value: "user",
                                label: "User",
                            },
                        ]}
                    />
                    {/* <div className="flex items-center">
                        <label htmlFor="subscriber" className="flex items-center cursor-pointer mr-3">
                            <div className="mr-3 block text-sm font-semibold tracking-wide text-gray-700 ">Subscriber</div>
                            <div className="relative">
                                <input
                                    type="checkbox"
                                    id="subscriber"
                                    className="sr-only"
                                    checked={isSubscriber}
                                    name="subscriber"
                                    onChange={toggleSubscriber}
                                />
                                <div className="block bg-gray-600 w-12 h-6 rounded-full"></div>
                                <div
                                    className={`dot absolute left-1 top-0.5 bg-white w-5 h-5 rounded-full transition-transform duration-300 transform ${isSubscriber ? 'translate-x-full bg-green-500' : 'translate-x-0 bg-gray-200'
                                        }`}
                                ></div>
                            </div>
                        </label>
                    </div> */}

                    <InputField
                        register={register}
                        name="accountId"
                        type="Number"
                        errorMessage={errors.accountId?.message}
                        placeholder="Enter account ID"
                        required
                        label="account ID"
                    />
                    <Button
                        type="submit"
                        disabled={isSubmitting}
                        value="Add User"
                        width="w-full"
                    />
                </form>
            </Modal>
        </div>
    )
}
