import { useSelector } from "react-redux"
import { authSelector, userSelector } from "redux/selectors/user"
import { IUser } from "types/index"


const useAccount = () => {
    const user = useSelector(userSelector)
    const isLoggedIn = useSelector(authSelector)
    const roles = useSelector((state: { user: IUser }) => state?.user?.role)

    return { user, isLoggedIn, roles }
}

export default useAccount
