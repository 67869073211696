import axios from "axios"

import { setAuthorizationHeader } from "utils/auth"
import { getItem, removeItem } from "services/localStorage.services"
import { redirectTo } from "./links"
import { ROUTES } from "./constants"

const API_DOMAIN = process.env.REACT_APP_API_DOMAIN || ""

const axiosInstance = axios.create({
    baseURL: API_DOMAIN,
})

axiosInstance.interceptors.response.use(
    response => {
        const token = response.headers["x-auth-token"]
        if (token) {
            setAuthorizationHeader(token)
        }
        return response
    },
    error => {
        const status = error?.response?.status
        const message = error?.response?.data
        if (status === 401 || message === "Invalid token.") {
            removeItem("persist:root")
            redirectTo(ROUTES.LOGIN)
            removeItem("x-auth-token")
        }

        return Promise.reject(error)
    },
)

export default axiosInstance

// set token from localStorage when application starts
setAuthorizationHeader(getItem("x-auth-token"))
