import { FC } from "react"
import { useLocation } from "react-router-dom"
import { NavLink } from "../Common/NavLink"
import { ROUTES } from "utils/constants"
import { HiOutlineUsers } from "react-icons/hi"
import {
    AiOutlineHome,
    AiOutlineIssuesClose,
    AiOutlineUser,
} from "react-icons/ai"
import { BsGeoAlt, BsPersonVcard, BsListCheck } from "react-icons/bs"
import { UserCircleIcon } from "@heroicons/react/outline"
import useAccount from "hooks/useAccount"

export const Sidebar: FC = () => {
    const { user } = useAccount()
    const { pathname } = useLocation()
    return (
        <div className="flex md:flex-shrink-0 h-full">
            {/*{!user && <SmallLoader />}*/}
            <div className="flex flex-col w-80">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex flex-col flex-grow border-r border-gray-200 bg-white overflow-y-auto">
                    <div className="flex-grow flex flex-col">
                        <div className="flex items-center mt-4 mb-2 justify-center">
                            <div className="h-24 w-24">
                                <img
                                    alt="logo"
                                    src="/images/profile.jpeg"
                                    className="h-full w-full mx-auto object-contain"
                                />
                            </div>
                        </div>
                        <nav className="flex-1 divide-y tracking-wide font-light">
                            <NavLink
                                active={pathname === ROUTES.DASHBOARD}
                                label="Dashboard"
                                icon={<AiOutlineHome className="h-6 w-6" />}
                                to={ROUTES.DASHBOARD}
                            />
                            {user?.role === "admin" && (
                                <>
                                    <NavLink
                                        active={pathname === ROUTES.USERS}
                                        label="Users"
                                        icon={
                                            <HiOutlineUsers
                                                className="h-6 w-6"
                                                strokeWidth={1.6}
                                            />
                                        }
                                        to={ROUTES.USERS}
                                    />
                                    {/* <NavLink
                                        active={pathname.includes("guard")}
                                        label="Guards"
                                        icon={
                                            <BsPersonVcard className="h-6 w-6" />
                                        }
                                        to={ROUTES.GUARDS}
                                    /> */}
                                    {/* <NavLink
                                        active={pathname.includes("client")}
                                        label="Clients"
                                        icon={
                                            <AiOutlineUser className="h-6 w-6" />
                                        }
                                        to={ROUTES.CLIENTS}
                                    /> */}
                                    {/* <NavLink
                                        active={pathname === ROUTES.SITES}
                                        label="Sites"
                                        icon={<BsGeoAlt className="h-6 w-6" />}
                                        to={ROUTES.SITES}
                                    /> */}
                                    {/* <NavLink
                                        active={pathname.includes("schedule")}
                                        label="Scheduling"
                                        icon={
                                            <BsListCheck className="h-6 w-6" />
                                        }
                                        to={ROUTES.SCHEDULING}
                                    /> */}
                                    {/* <NavLink
                                        active={pathname.includes("supervisor")}
                                        label="Supervisor"
                                        icon={
                                            <UserCircleIcon className="h-6 w-6" />
                                        }
                                        to={ROUTES.SUPERVISOR}
                                    /> */}
                                    {/* <NavLink
                                        active={pathname.includes("issues")}
                                        label="Activities"
                                        icon={
                                            <AiOutlineIssuesClose className="h-6 w-6" />
                                        }
                                        to={ROUTES.Issues}
                                    /> */}
                                </>
                            )}
                            {/*<Disclosure as="div">*/}
                            {/*    {({ open }) => (*/}
                            {/*        <>*/}
                            {/*            <Disclosure.Button className="w-full">*/}
                            {/*                <a*/}
                            {/*                    className={`${*/}
                            {/*                        pathname.includes("user")*/}
                            {/*                            ? "bg-gray-100 font-light border-r-2 text-gray-900"*/}
                            {/*                            : "text-gray-600 hover:bg-gray-100"*/}
                            {/*                    } flex justify-between py-1 px-6  items-center group w-full`}>*/}
                            {/*                    <div className="flex items-center">*/}
                            {/*                        <UsersIcon*/}
                            {/*                            className="h-6 w-6"*/}
                            {/*                            strokeWidth={1}*/}
                            {/*                        />*/}
                            {/*                        <span className="text-base text-gray-900 p-3 rounded-md">*/}
                            {/*                            Users*/}
                            {/*                        </span>*/}
                            {/*                    </div>*/}
                            {/*                    <ChevronUpIcon*/}
                            {/*                        className={`${*/}
                            {/*                            open*/}
                            {/*                                ? "rotate-180 transform"*/}
                            {/*                                : ""*/}
                            {/*                        } duration-200 h-6 w-6 text-gray-600`}*/}
                            {/*                    />*/}
                            {/*                </a>*/}
                            {/*            </Disclosure.Button>*/}
                            {/*            <div className="overflow-hidden">*/}
                            {/*                <Transition*/}
                            {/*                    show={open}*/}
                            {/*                    as="div"*/}
                            {/*                    enter="transition ease-in-out duration-300 transform"*/}
                            {/*                    enterFrom="-translate-y-full"*/}
                            {/*                    enterTo="translate-y-0"*/}
                            {/*                    leave="transition ease-in-out duration-300 transform"*/}
                            {/*                    leaveFrom="translate-y-0"*/}
                            {/*                    leaveTo="-translate-y-full">*/}
                            {/*                    <Disclosure.Panel className="divide-y shadow-inner flex flex-col transition-all duration-700 bg-gray-100 text-gray-800 ">*/}
                            {/*                        <NavLink*/}
                            {/*                            to="/user/admin"*/}
                            {/*                            active={false}*/}
                            {/*                            label={"User"}*/}
                            {/*                        />*/}
                            {/*                        <NavLink*/}
                            {/*                            to="/user/manager"*/}
                            {/*                            active={false}*/}
                            {/*                            label="UUUU"*/}
                            {/*                        />*/}
                            {/*                    </Disclosure.Panel>*/}
                            {/*                </Transition>*/}
                            {/*            </div>*/}
                            {/*        </>*/}
                            {/*    )}*/}
                            {/*</Disclosure>*/}
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
}
